import { comparePatch } from "./patch-helpers";

export enum QueueTypeS {
  RANKED_SOLO = "ranked_solo_5x5",
  RANKED_FLEX = "ranked_flex_sr",
  NORMAL_BLIND = "normal_blind_5x5",
  NORMAL_DRAFT = "normal_draft_5x5",
  ARAM = "normal_aram",
  ARAM_CLASH = "aram_clash",
  NEXUS_BLITZ = "nexus_blitz",
  CLASH = "clash_5x5",
  URF = "urf",
  ARURF = "arurf",
  ONE_FOR_ALL = "one_for_all",
  ULT_SPELLBOOK = "ultimate_spellbook",
  RANKED_TFT = "ranked_tft",
  CUSTOM_GAME = "custom_game",
  PICK_URF = "pick_urf",
  ARENA = "arena",
  ARENA_DUO = "arena-duo", // Used for arena duo tier list
  BOTS_INTRO = "bots_intro",
  BOTS_BEGINNER = "bots_beginner",
  BOTS_INTERMEDIATE = "bots_intermediate",
  QUICKPLAY = "quickplay",
}

export enum QueueTypeN {
  RANKED_SOLO = 420,
  RANKED_FLEX = 440,
  NORMAL_BLIND = 430,
  NORMAL_DRAFT = 400,
  ARAM = 450,
  NEXUS_BLITZ = 1300,
  CLASH = 700,
  ARAM_CLASH = 720,
  URF = 900,
  ARURF = 900,
  ONE_FOR_ALL = 1020,
  ULT_SPELLBOOK = 1400,
  RANKED_TFT = 1100,
  CUSTOM_GAME = -1,
  TFTRegularMatch = 1090,
  TFTRankedMatch = 1100,
  PICK_URF = 1900,
  ARENA = 1700,
  BOTS_INTRO = 830,
  BOTS_BEGINNER = 840,
  BOTS_INTERMEDIATE = 850,
  QUICKPLAY = 490,
}

export enum LeagueLauncherQueueIds {}

export class QueueTypeC {
  private readonly stringId: string;
  private readonly numId: number;
  private static QueueTypeS_Obj = Object.fromEntries(Object.entries(QueueTypeS));
  private static QueueTypeN_Obj: Record<string, QueueTypeN> = Object.fromEntries(
    Object.entries(QueueTypeN)
      .map(([key, value]) => [key, Number(value)])
      .filter((queueType) => typeof queueType[1] === "number" && !isNaN(queueType[1])),
  );

  constructor(stringValue: string, numValue: number) {
    this.stringId = stringValue;
    this.numId = numValue;
  }

  public static convertToInt(stringValue: string) {
    for (const [key, value] of Object.entries(this.QueueTypeS_Obj)) {
      if (value === stringValue) {
        return this.QueueTypeN_Obj[key];
      }
    }
    return 0;
  }
  public static convertToString(numValue: number) {
    for (const [key, value] of Object.entries(this.QueueTypeN_Obj)) {
      if (value === numValue) {
        return this.QueueTypeS_Obj[key];
      }
    }
    return "";
  }
  public getString() {
    return this.stringId;
  }
  public getInt() {
    return this.numId;
  }
}

export function getQueueTypeName(queueType: number | string) {
  switch (queueType) {
    case QueueTypeN.RANKED_SOLO:
    case QueueTypeS.RANKED_SOLO:
      return "Ranked Solo";
    case QueueTypeN.RANKED_FLEX:
    case QueueTypeS.RANKED_FLEX:
      return "Ranked Flex";
    case QueueTypeN.NORMAL_BLIND:
    case QueueTypeS.NORMAL_BLIND:
      return "Normal Blind";
    case QueueTypeN.NORMAL_DRAFT:
    case QueueTypeS.NORMAL_DRAFT:
      return "Normal Draft";
    case QueueTypeN.ARAM:
    case QueueTypeS.ARAM:
      return "ARAM";
    case QueueTypeN.ARAM_CLASH:
    case QueueTypeS.ARAM_CLASH:
      return "ARAM Clash";
    case QueueTypeN.QUICKPLAY:
    case QueueTypeS.QUICKPLAY:
      return "Quickplay";
    case 1200: // Deprecated Nexus Blitz ID
    case QueueTypeN.NEXUS_BLITZ:
    case QueueTypeS.NEXUS_BLITZ:
      return "Nexus Blitz";
    case QueueTypeN.CLASH:
    case QueueTypeS.CLASH:
      return "Clash";
    case "urf":
      return "URF";
    case QueueTypeN.ARURF:
    case QueueTypeS.ARURF:
      return "ARURF";
    case QueueTypeN.ONE_FOR_ALL:
    case QueueTypeS.ONE_FOR_ALL:
      return "One for All";
    case QueueTypeN.ULT_SPELLBOOK:
    case QueueTypeS.ULT_SPELLBOOK:
      return "Ult Spellbook";
    case "ranked_flex_tt":
      return "Twisted Treeline";
    case QueueTypeS.RANKED_TFT:
      return "Teamfight Tactics";
    case QueueTypeN.PICK_URF:
    case QueueTypeS.PICK_URF:
      return "URF";
    case QueueTypeN.ARENA:
    case QueueTypeS.ARENA:
    case QueueTypeS.ARENA_DUO:
      return "Arena";
    case QueueTypeN.BOTS_INTRO:
    case QueueTypeS.BOTS_INTRO:
      return "Bots (Intro)";
    case QueueTypeN.BOTS_BEGINNER:
    case QueueTypeS.BOTS_BEGINNER:
      return "Bots (Beginner)";
    case QueueTypeN.BOTS_INTERMEDIATE:
    case QueueTypeS.BOTS_INTERMEDIATE:
      return "Bots (Interm.)";
    case QueueTypeS.CUSTOM_GAME:
      return "Custom";
    default:
      return "";
  }
}

export type ArenaModeType = "8-player" | "16-player";
export function getArenaModeType(patch: string): ArenaModeType {
  if (comparePatch(patch, "14.9") === -1) {
    return "8-player";
  }

  return "16-player";
}
