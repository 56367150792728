export const INTERNAL_CURRENT_SEASON = 23; // internal season id
export const INTERNAL_PREV_SEASON = 22;
export const ACTUAL_CURRENT_SEASON = 14; // actual season number

export const seasonMap: Record<number, { season: number; split?: number }> = {
  23: { season: 14, split: 2 },
  22: { season: 14, split: 1 },
  21: { season: 13, split: 2 },
  20: { season: 13, split: 1 },
  18: { season: 12 },
  16: { season: 11 },
  14: { season: 10 },
  9: { season: 9 },
  8: { season: 8 },
  7: { season: 7 },
  6: { season: 6 },
  5: { season: 5 },
  4: { season: 4 },
  3: { season: 3 },
} as const;

export function getSeasonLabel(seasonId: number, delimiter = "-") {
  const { season, split } = seasonMap[seasonId] || {};

  if (!season) return "";
  else if (!split) return String(season);
  else return season + delimiter + split;
}
