import { window } from "global";
import React from "react";
import classnames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { useGlobal } from "reactn";
import MediaQuery from "components/MediaQuery";
import SearchBar from "components/SearchBar";
import { LoginButton } from "components/Accounts/Login";
import NotificationContainer from "./Masthead/Notifications/NotificationContainer";
import SettingsContainer from "./Masthead/Settings/views/SettingsContainer";
import { useLoginState, useUserPremiumState } from "lib/hooks/userHooks";
import { ReactComponent as BlueManPlus } from "svg/blue-man-plus.svg";
import { ReactComponent as StarsThree } from "svg/stars-three.svg";
import { ReactComponent as SearchIconWhite } from "svg/search-icon-2-white.svg";
import { ReactComponent as SearchIconWhiteLightMode } from "svg/search-icon-2-light-mode.svg";
import { ReactComponent as Hamburger } from "svg/hamburger.svg";
import { ReactComponent as ULogo } from "svg/logos/ugg-u-logo.svg";
import FeedbackIcon from "./Masthead/Feedback/FeedbackIcon";
import { useLightMode } from "hooks/general-hooks";
import AppDownloadButton from "./Buttons/AppDownloadButton";
import UAParser from "ua-parser-js";

const parser = new UAParser();
const os = parser.getOS();
const isMac = os?.name?.match(/mac/gi);

const Masthead = (props) => {
  const { sideNavOpen, setSideNavOpen } = props;
  const location = useLocation();
  const [verificationBar] = useGlobal("verificationBar");
  const { lightModeOn } = useLightMode();
  const isLoggedIn = useLoginState();
  const isPremium = useUserPremiumState();
  const isLanding = location.pathname === "/" ? true : false;
  const [windowBreakpoint] = useGlobal("responsive");

  const verificationBarActive = verificationBar ? "verification-bar-active" : "";
  const isMobile =
    windowBreakpoint === "MOBILE_SMALL" || windowBreakpoint === "MOBILE_MEDIUM" || windowBreakpoint === "MOBILE_LARGE";

  if (!window) {
    return (
      <div id="masthead" className={`${verificationBarActive} ${isLanding ? "is-landing" : ""}`}>
        <div
          className={"masthead-container"}
          style={{
            backgroundColor: isLanding && !isMobile ? "transparent" : "",
            borderBottom: isLanding && !isMobile ? "0px" : "",
          }}
        />
      </div>
    );
  }

  return (
    <div id="masthead" className={`${verificationBarActive} ${isLanding ? "is-landing" : ""}`}>
      <div
        className={"masthead-container"}
        style={{
          backgroundColor: isLanding && !isMobile ? "transparent" : "",
          borderBottom: isLanding && !isMobile ? "0px" : "",
        }}
      >
        <MediaQuery min="MOBILE_SMALL" max="TABLET">
          <Hamburger className="hamburger-icon" onClick={() => setSideNavOpen(!sideNavOpen)} />
          <Link to="/" className="flex-center">
            <ULogo className="ugg-u-logo" />
          </Link>
        </MediaQuery>
        <MediaQuery min="DESKTOP_SMALL" max="DESKTOP_LARGE">
          {location.pathname !== "/app" && (
            <AppDownloadButton url={"/app?utm_source=uggtop"} label="Download Now" icon={"cloud"} />
            // <AppDownloadButton url={"/app?utm_source=uggtop"} label="Download Now" icon={isMac ? "apple" : "windows"} />
          )}
        </MediaQuery>
        <MediaQuery min="MOBILE_LARGE" max="DESKTOP_LARGE">
          <div className="masthead-search-bar-container">
            {location.pathname !== "/" && !location.pathname.includes("lg-splash") && (
              <SearchBar
                theme={lightModeOn ? "light" : "dark"}
                materialDesign
                placeholder={"Search Summoner or Champion"}
                isMasthead
              />
            )}
          </div>
        </MediaQuery>
        <div className={"masthead-right"}>
          <MediaQuery min="MOBILE_SMALL" max="MOBILE_MEDIUM">
            <div className="flex-center">
              {location.pathname !== "/" && !location.pathname.includes("lg-splash") && lightModeOn ? (
                <SearchIconWhiteLightMode
                  style={{ height: "16px", width: "16px" }}
                  onClick={() => setSideNavOpen(!sideNavOpen)}
                />
              ) : (
                <SearchIconWhite style={{ height: "16px", width: "16px" }} onClick={() => setSideNavOpen(!sideNavOpen)} />
              )}
            </div>
          </MediaQuery>
          <SettingsContainer />
          <FeedbackIcon />
          <MediaQuery min="MOBILE_LARGE" max="DESKTOP_LARGE">
            <div>
              <NotificationContainer />
            </div>
          </MediaQuery>
          {!isLoggedIn && (
            <>
              <MediaQuery min="TABLET" max="DESKTOP_LARGE">
                <div className="masthead_account">
                  <div className="masthead_account-buttons">
                    <LoginButton>
                      <div className="btn-blue btn-blue_hover" style={{ height: "40px", width: "100px" }}>
                        Log In
                      </div>
                    </LoginButton>
                  </div>
                </div>
              </MediaQuery>
              <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE">
                <div className="masthead_blue-man">
                  <Link to={"/login"}>
                    <BlueManPlus style={{ height: "16px", width: "16px" }} />
                  </Link>
                </div>
              </MediaQuery>
            </>
          )}
          {!isPremium.isPremium && isLoggedIn && (
            <>
              <MediaQuery min="TABLET" max="DESKTOP_LARGE">
                <div className="masthead_account">
                  <div className="masthead_account-buttons">
                    <Link className="login-btn" to={"/settings/boost"}>
                      <div
                        className="btn-blue btn-blue_hover"
                        style={{ width: "126px", height: "40px", fontSize: "13px", fontWeight: 700 }}
                      >
                        <div style={{ height: "16px", width: "16px", marginRight: "6px" }}>
                          <StarsThree style={{ height: "16px", width: "16px" }} />
                        </div>
                        Go Ad-Free
                      </div>
                    </Link>
                  </div>
                </div>
              </MediaQuery>
              <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE">
                <div className="masthead_blue-man">
                  <Link to={"/settings/boost"}>
                    <StarsThree style={{ height: "16px", width: "16px" }} />
                  </Link>
                </div>
              </MediaQuery>
            </>
          )}
          {isPremium.isPremium && isLoggedIn && (
            <>
              <MediaQuery min="MOBILE_SMALL" max="DESKTOP_LARGE">
                <div className="masthead_blue-man-premium masthead_blue-man">
                  <Link to={"/settings/boost"}>
                    <StarsThree style={{ height: "16px", width: "16px" }} />
                  </Link>
                </div>
              </MediaQuery>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Masthead;
