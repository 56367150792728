import { window, document } from "global";
import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { TaglessAdUnits, TagBasedAdUnits, RampWindow } from "./ramp.interface";

export type RampHandle = {
  addUnits: () => void;
  cleanUp(): () => Promise<void> | undefined;
  destroyAllUnits: () => void;
};

interface RampProps {
  PUB_ID: string;
  WEBSITE_ID: string;
  pwUnits: Array<{ type: TaglessAdUnits }>;
  pathname: string;
  forcePath?: string;
}

let globalRampComponentLoaded = false;

const Ramp = forwardRef(({ pwUnits, pathname, forcePath, PUB_ID, WEBSITE_ID }: RampProps, ref) => {
  const [rampComponentLoaded, setRampComponentLoaded] = useState(false);
  const rampWindow = window as RampWindow;

  // Function to add ad units
  const addUnits = () => {
    rampWindow.ramp.que.push(() => {
      console.log("addUnits", pwUnits);

      try {
        document.getElementById("siteSkinContainer").remove();
      } catch (err) {}

      rampWindow.ramp
        .addUnits(pwUnits)
        .catch((e: any) => console.warn("Error adding units:", e))
        .finally(() => {
          rampWindow.ramp.displayUnits();
        });
    });
  };

  // Cleanup function to remove ad units
  const cleanUp = () => {
    if (!rampWindow.ramp?.settings?.slots) return;
    const tagBasedAdUnits = Object.values(TagBasedAdUnits);
    const slots = Object.entries(rampWindow.ramp.settings.slots).filter(([id, slot]) => {
      return tagBasedAdUnits.every((adUnit) => {
        return !slot?.type.includes(adUnit) && !slot?.id.includes(adUnit);
      });
    });
    const slotsToRemove = slots.map((slot) => slot[0]);
    return rampWindow.ramp?.destroyUnits?.(slotsToRemove);
  };

  const destroyAllUnits = () => {
    rampWindow?.ramp?.destroyUnits?.("all");
  };

  useEffect(() => {
    return () => {
      console.log("cleanup all ads");
      rampWindow?.ramp?.destroyUnits?.("all");
    };
  }, []);

  useEffect(() => {
    if (rampComponentLoaded || globalRampComponentLoaded) {
      cleanUp()?.then(addUnits);
    }
  }, [pwUnits, pathname]);

  useEffect(() => {
    if (!PUB_ID || !WEBSITE_ID) {
      console.log("Missing Publisher Id and Website Id");
      return;
    }

    if (!rampComponentLoaded && !globalRampComponentLoaded) {
      setRampComponentLoaded(true);
      globalRampComponentLoaded = true;
      rampWindow.ramp = rampWindow.ramp || {};
      rampWindow.ramp.que = rampWindow.ramp.que || [];
      rampWindow.ramp.passiveMode = true;
      rampWindow.ramp.forcePath = forcePath || rampWindow.ramp.forcePath;

      // Load the Ramp configuration script
      const configScript = document.createElement("script");
      configScript.src = `https://cdn.intergient.com/${PUB_ID}/${WEBSITE_ID}/ramp.js`;
      document.head.appendChild(configScript);

      configScript.onload = addUnits;
    }

    // Cleanup function to remove units on component unmount
    return () => {
      cleanUp();
    };
  }, [rampComponentLoaded]);

  // Effect to handle forcePath updates
  useEffect(() => {
    let currentPath = forcePath || pathname;

    // Exclude root path ("/") when forcePath is not provided
    if (currentPath === "/" && !forcePath) return;

    if (!rampComponentLoaded) return;

    rampWindow.ramp.que.push(() => {
      rampWindow.ramp.setPath(currentPath || "");
    });
  }, [forcePath, pathname, rampComponentLoaded]);

  useImperativeHandle(ref, () => {
    return {
      addUnits,
      cleanUp,
      destroyAllUnits,
    };
  });

  return null;
});

export default Ramp;
