import { getRegionUrl, getMastheadRegionImg } from "@outplayed/riot-assets";

export enum RegionS {
  NA = "na1",
  EUW = "euw1",
  KR = "kr",
  EUN = "eun1",
  BR = "br1",
  LA1 = "la1",
  LA2 = "la2",
  OC = "oc1",
  RU = "ru",
  TR = "tr1",
  JP = "jp1",
  WORLD = "world",
  PH = "ph2",
  SG = "sg2",
  TH = "th2",
  TW = "tw2",
  VN = "vn2",
}

export enum RegionN {
  NA = 1,
  EUW = 2,
  KR = 3,
  EUN = 4,
  BR = 5,
  LA1 = 6,
  LA2 = 7,
  OC = 8,
  RU = 9,
  TR = 10,
  JP = 11,
  WORLD = 12,
  PH = 13,
  SG = 14,
  TH = 15,
  TW = 16,
  VN = 17,
}

export class RegionC {
  private readonly stringId: string;
  private readonly numId: number;
  private static RegionS_Obj = Object.fromEntries(Object.entries(RegionS));
  private static RegionN_Obj: Record<string, RegionN> = Object.fromEntries(
    Object.entries(RegionN)
      .map(([key, value]) => [key, Number(value)])
      .filter((region) => typeof region[1] === "number" && !isNaN(region[1])),
  );

  constructor(stringValue: string, numValue: number) {
    this.stringId = stringValue;
    this.numId = numValue;
  }

  public toString() {
    return this.stringId;
  }
  public toInt() {
    return this.numId;
  }
  public static convertToString(numValue: number) {
    for (const [key, value] of Object.entries(this.RegionN_Obj)) {
      if (value === numValue) {
        return this.RegionS_Obj[key];
      }
    }
    return "";
  }
  public static convertToInt(stringValue: string) {
    for (const [key, value] of Object.entries(this.RegionS_Obj)) {
      if (value === stringValue) {
        return this.RegionN_Obj[key];
      }
    }
    return 0;
  }
}

export function getRegionList(includeWorlds = false) {
  return Object.entries(RegionS)
    .filter(([key, value]) => {
      return includeWorlds || (!includeWorlds && value !== RegionS.WORLD);
    })
    .map(([key, value]) => value);
}

export function getBlockImg(region: string) {
  switch (region) {
    case "world":
      return getMastheadRegionImg("World");
    case "na1":
      return getMastheadRegionImg("NA");
    case "euw1":
      return getMastheadRegionImg("EUW");
    case "kr":
      return getMastheadRegionImg("KR");
    case "br1":
      return getMastheadRegionImg("BR");
    case "eun1":
      return getMastheadRegionImg("EUN");
    case "jp1":
      return getMastheadRegionImg("JP");
    case "la1":
      return getMastheadRegionImg("LAN");
    case "la2":
      return getMastheadRegionImg("LAS");
    case "oc1":
      return getMastheadRegionImg("OCE");
    case "ru":
      return getMastheadRegionImg("RU");
    case "tr1":
      return getMastheadRegionImg("TR");
    case "ph2":
      return getMastheadRegionImg("PH");
    case "sg2":
      return getMastheadRegionImg("SG");
    case "th2":
      return getMastheadRegionImg("TH");
    case "tw2":
      return getMastheadRegionImg("TW");
    case "vn2":
      return getMastheadRegionImg("VN");
    default:
      return "";
  }
}

export function getImg(region: string) {
  switch (region) {
    case "world":
      return getRegionUrl("world");
    case "na1":
      return getRegionUrl("NA");
    case "euw1":
      return getRegionUrl("EUW");
    case "kr":
      return getRegionUrl("flags/kr");
    case "br1":
      return getRegionUrl("flags/br");
    case "eun1":
      return getRegionUrl("EUN");
    case "jp1":
      return getRegionUrl("flags/jp");
    case "la1":
      return getRegionUrl("LAN");
    case "la2":
      return getRegionUrl("LAS");
    case "oc1":
      return getRegionUrl("OCE");
    case "ru":
      return getRegionUrl("flags/ru");
    case "tr1":
      return getRegionUrl("flags/tr");
    default:
      return "";
  }
}

export function getShortName(region: string) {
  switch (region) {
    case "world":
      return "World";
    case "na1":
      return "NA";
    case "euw1":
      return "EUW";
    case "kr":
      return "KR";
    case "br1":
      return "BR";
    case "eun1":
      return "EUN";
    case "jp1":
      return "JP";
    case "la1":
      return "LAN";
    case "la2":
      return "LAS";
    case "oc1":
      return "OCE";
    case "ru":
      return "RU";
    case "tr1":
      return "TR";
    case "ph2":
      return "PH";
    case "sg2":
      return "SG";
    case "th2":
      return "TH";
    case "tw2":
      return "TW";
    case "vn2":
      return "VN";
    default:
      return "";
  }
}

export function getApiRegion(region: string) {
  switch (region) {
    case "world":
      return "world";
    case "na1":
      return "na";
    case "euw1":
    case "eun1":
      return "eu";
    case "kr":
      return "KR";
    case "br1":
      return "BR";
    case "jp1":
      return "JP";
    case "la1":
      return "LAN";
    case "la2":
      return "LAS";
    case "oc1":
      return "OCE";
    case "ru":
      return "RU";
    case "tr1":
      return "TR";
    case "ph2":
      return "PH";
    case "sg2":
      return "SG";
    case "th2":
      return "TH";
    case "tw2":
      return "TW";
    case "vn2":
      return "VN";
    default:
      return "";
  }
}

export function getLeagueName(league: string) {
  switch (league.toLowerCase()) {
    case "world":
      return "Worlds";
    case "lcs":
      return "LCS";
    case "lec":
      return "LEC";
    case "lck":
      return "LCK";
    case "lpl":
      return "LPL";
    case "lms":
      return "LMS";
    case "cblol":
      return "CBLOL";
    case "lcl":
      return "LCL";
    case "ljl":
      return "LJL";
    case "lla":
      return "LLA";
    case "opl":
      return "OPL";
    case "lst":
      return "LST";
    case "tcl":
      return "TCL";
    case "vcs":
      return "VCS";
    default:
      return "";
  }
}

export function convertLeagueClientRegion(region: string, defaultRegion?: string) {
  switch (region) {
    case "br":
    case "br1":
      return "br1";
    case "eune":
    case "eun1":
      return "eun1";
    case "euw":
    case "euw1":
      return "euw1";
    case "lan":
    case "la1":
      return "la1";
    case "las":
    case "la2":
      return "la2";
    case "na":
    case "na1":
      return "na1";
    case "oce":
    case "oc1":
      return "oc1";
    case "ru":
    case "ru1":
      return "ru";
    case "tr":
    case "tr1":
      return "tr1";
    case "jp":
    case "jp1":
      return "jp1";
    case "kr":
      return "kr";
    case "ph":
    case "ph2":
      return "ph2";
    case "sg":
    case "sg2":
      return "sg2";
    case "th":
    case "th2":
      return "th2";
    case "tw":
    case "tw2":
      return "tw2";
    case "vn":
    case "vn2":
      return "vn2";
    default:
      return defaultRegion === undefined ? "na1" : defaultRegion;
  }
}
