import { getRegionUrl, getBlockRegionUrl, getMastheadRegionImg } from "@outplayed/riot-assets";

function getRegionById(region) {
  switch (region) {
    case 1:
      return "na1";
    case 2:
      return "euw1";
    case 3:
      return "kr";
    case 4:
      return "eun1";
    case 5:
      return "br1";
    case 6:
      return "la1";
    case 7:
      return "la2";
    case 8:
      return "oc1";
    case 9:
      return "ru";
    case 10:
      return "tr1";
    case 11:
      return "jp1";
    case 12:
      return "world";
    case 13:
      return "ph2";
    case 14:
      return "sg2";
    case 15:
      return "th2";
    case 16:
      return "tw2";
    case 17:
      return "vn2";
    default:
      return -1;
  }
}

function getRegionList(includeWorlds = false) {
  let regions = [];

  for (let i = 1; i <= 17; i++) {
    if (!includeWorlds && i === 12) continue;

    regions.push(getRegionById(i));
  }

  return regions;
}

function getRegionId(region) {
  switch (region) {
    case "na1":
      return 1;
    case "euw1":
      return 2;
    case "kr":
      return 3;
    case "eun1":
      return 4;
    case "br1":
      return 5;
    case "la1":
      return 6;
    case "la2":
      return 7;
    case "oc1":
      return 8;
    case "ru":
      return 9;
    case "tr1":
      return 10;
    case "jp1":
      return 11;
    case "world":
      return 12;
    case "ph2":
      return 13;
    case "sg2":
      return 14;
    case "th2":
      return 15;
    case "tw2":
      return 16;
    case "vn2":
      return 17;
    default:
      return undefined;
  }
}

function getBlockImg(region) {
  switch (region) {
    case "world":
      return getMastheadRegionImg("World");
    case "na1":
      return getMastheadRegionImg("NA");
    case "euw1":
      return getMastheadRegionImg("EUW");
    case "kr":
      return getMastheadRegionImg("KR");
    case "br1":
      return getMastheadRegionImg("BR");
    case "eun1":
      return getMastheadRegionImg("EUN");
    case "jp1":
      return getMastheadRegionImg("JP");
    case "la1":
      return getMastheadRegionImg("LAN");
    case "la2":
      return getMastheadRegionImg("LAS");
    case "oc1":
      return getMastheadRegionImg("OCE");
    case "ru":
      return getMastheadRegionImg("RU");
    case "tr1":
      return getMastheadRegionImg("TR");
    case "ph2":
      return getMastheadRegionImg("PH");
    case "sg2":
      return getMastheadRegionImg("SG");
    case "th2":
      return getMastheadRegionImg("TH");
    case "tw2":
      return getMastheadRegionImg("TW");
    case "vn2":
      return getMastheadRegionImg("VN");
    default:
      return "";
  }
}

function getBlockImgByLeagueId(region, isMasthead) {
  switch (region) {
    case "world":
      return getBlockRegionUrl("World", isMasthead);
    case "lcs":
      return getBlockRegionUrl("NA", isMasthead);
    case "euw":
      return getBlockRegionUrl("EUW", isMasthead);
    case "lck":
      return getBlockRegionUrl("KR", isMasthead);
    case "cblol":
      return getBlockRegionUrl("BR", isMasthead);
    case "lec":
      return getBlockRegionUrl("EUN", isMasthead);
    case "ljl":
      return getBlockRegionUrl("JP", isMasthead);
    case "la1":
      return getBlockRegionUrl("LAN", isMasthead);
    case "la2":
      return getBlockRegionUrl("LAS", isMasthead);
    case "oc1":
      return getBlockRegionUrl("OCE", isMasthead);
    case "ru":
      return getBlockRegionUrl("RU", isMasthead);
    case "tcl":
      return getBlockRegionUrl("TR", isMasthead);
    default:
      return "";
  }
}

function getImg(region) {
  switch (region) {
    case "world":
      return getRegionUrl("world");
    case "na1":
      return getRegionUrl("NA");
    case "euw1":
      return getRegionUrl("EUW");
    case "kr":
      return getRegionUrl("flags/kr");
    case "br1":
      return getRegionUrl("flags/br");
    case "eun1":
      return getRegionUrl("EUN");
    case "jp1":
      return getRegionUrl("flags/jp");
    case "la1":
      return getRegionUrl("LAN");
    case "la2":
      return getRegionUrl("LAS");
    case "oc1":
      return getRegionUrl("OCE");
    case "ru":
      return getRegionUrl("flags/ru");
    case "tr1":
      return getRegionUrl("flags/tr");
    default:
      return "";
  }
}

function getShortName(region) {
  switch (region) {
    case "world":
      return "World";
    case "na1":
      return "NA";
    case "euw1":
      return "EUW";
    case "kr":
      return "KR";
    case "br1":
      return "BR";
    case "eun1":
      return "EUN";
    case "jp1":
      return "JP";
    case "la1":
      return "LAN";
    case "la2":
      return "LAS";
    case "oc1":
      return "OCE";
    case "ru":
      return "RU";
    case "tr1":
      return "TR";
    case "ph2":
      return "PH";
    case "sg2":
      return "SG";
    case "th2":
      return "TH";
    case "tw2":
      return "TW";
    case "vn2":
      return "VN";
    default:
      return "";
  }
}

function getApiRegion(region) {
  switch (region) {
    case "world":
      return "world";
    case "na1":
      return "na";
    case "euw1":
    case "eun1":
      return "eu";
    case "kr":
      return "KR";
    case "br1":
      return "BR";
    case "jp1":
      return "JP";
    case "la1":
      return "LAN";
    case "la2":
      return "LAS";
    case "oc1":
      return "OCE";
    case "ru":
      return "RU";
    case "tr1":
      return "TR";
    case "ph2":
      return "PH";
    case "sg2":
      return "SG";
    case "th2":
      return "TH";
    case "tw2":
      return "TW";
    case "vn2":
      return "VN";
    default:
      return "";
  }
}

function getLeagueName(league) {
  switch (league.toLowerCase()) {
    case "world":
      return "Worlds";
    case "lcs":
      return "LCS";
    case "lec":
      return "LEC";
    case "lck":
      return "LCK";
    case "lpl":
      return "LPL";
    case "lms":
      return "LMS";
    case "cblol":
      return "CBLOL";
    case "lcl":
      return "LCL";
    case "ljl":
      return "LJL";
    case "lla":
      return "LLA";
    case "opl":
      return "OPL";
    case "lst":
      return "LST";
    case "tcl":
      return "TCL";
    case "vcs":
      return "VCS";
    default:
      return "";
  }
}

export { getRegionId, getRegionById, getImg, getShortName, getBlockImg, getApiRegion, getLeagueName, getRegionList };
