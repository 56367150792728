import * as React from "react";
import type { SVGProps } from "react";
export const SvgGameVal = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 32 32" {...props}>
    <path
      fill="#FF4655"
      d="M28.349 15.62V6.153c0-.146-.183-.206-.28-.097L17.726 18.979c-.085.097-.012.255.122.255h7.471a.64.64 0 0 0 .511-.255l2.36-2.945a.624.624 0 0 0 .159-.414Zm-24.19.426 7.763 9.71a.652.652 0 0 0 .523.244h7.471c.134 0 .207-.146.122-.243L4.28 6.068c-.097-.11-.28-.048-.28.098v9.466c.012.147.06.293.158.414Z"
    />
  </svg>
);
