export function compareObjects(obj1: Record<any, any>, obj2: Record<any, any>) {
  const objKeys1 = Object.keys(obj1);
  const objKeys2 = Object.keys(obj2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (const key of objKeys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

export function removeKeys(obj: Record<string, any>, arrayOfKeys: string[]) {
  const clone = { ...obj };
  for (const key of arrayOfKeys) {
    delete clone[key];
  }

  return clone;
}
