import { window } from "global";
import fetch from "node-fetch";
import { buildQueryParams } from "lib/general-helper";
import { getApiRegion } from "lib/region_helper";

export const API_VERSION = "1.5";
export const API_ROOT_PATH = `https://stats.u.gg/lol/${API_VERSION}`;
export const API_ROOT_PATH_V2 = `https://stats2.u.gg/lol/${API_VERSION}`;
export const API_UGG = "https://api.u.gg";

export function getApiUggRoot(region) {
  if (region) {
    return `https://${getApiRegion(region).toLowerCase()}.api.u.gg`;
  }

  return API_UGG;
}

export function getApiRoot(patch) {
  if (!patch) {
    return API_ROOT_PATH_V2;
  }

  const patchSplit = patch.split("_");
  const season = patchSplit[0];
  const version = patchSplit[1];

  if (season < 8 || (season == 8 && version <= 23)) {
    return API_ROOT_PATH;
  }

  return API_ROOT_PATH_V2;
}

export function fetchRawApi(url) {
  return fetch(url, { method: "GET" });
}

export function fetchWrapper(fetchRequest, callback) {
  fetchRequest()
    .then((response) => {
      if (response.status !== 200) {
        throw new Error();
      }
      return response.json();
    })
    .then((json) => {
      callback(json);
    })
    .catch((err) => {
      callback(null);
    });
}

export function regionMapping(region) {
  switch (region) {
    case "na1":
      return 1;
    case "euw1":
      return 2;
    case "kr":
      return 3;
    case "eun1":
      return 4;
    case "br1":
      return 5;
    case "la1":
      return 6;
    case "la2":
      return 7;
    case "oc1":
      return 8;
    case "ru":
      return 9;
    case "tr1":
      return 10;
    case "jp1":
      return 11;
    case "world":
      return 12;
    case "ph2":
      return 13;
    case "sg2":
      return 14;
    case "th2":
      return 15;
    case "tw2":
      return 16;
    case "vn2":
      return 17;
    default:
      return -1;
  }
}

export function roleMapping(role) {
  switch (role) {
    case "jungle":
      return 1;
    case "support":
    case "supp":
      return 2;
    case "adc":
      return 3;
    case "top":
      return 4;
    case "middle":
    case "mid":
      return 5;
    case "none":
      return 6;
    case "all":
      return 7;
    default:
      return -1;
  }
}

export function roleMappingFromNumber(role) {
  switch (role) {
    case 1:
      return "jungle";
    case 2:
      return "supp";
    case 3:
      return "adc";
    case 4:
      return "top";
    case 5:
      return "mid";
    case 6:
      return "none";
    case 7:
      return "all";
    default:
      return -1;
  }
}

export function queueTypeMapping(queueType) {
  switch (queueType) {
    case "normal_draft_5x5":
      return 400;
    case "ranked_solo_5x5":
      return 420;
    case "normal_blind_5x5":
      return 430;
    case "ranked_flex_sr":
      return 440;
    case "normal_aram":
      return 450;
    case "clash_5x5":
      return 700;
    case "nexus_blitz":
      return 1300;
    default:
      return -1;
  }
}
