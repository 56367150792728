import { window } from "global";
import React, { useEffect, useState, useRef } from "react";
import { Route, useLocation, useHistory } from "react-router-dom";
import classNames from "classnames";
import MediaQuery from "components/MediaQuery";
import Masthead from "components/Masthead";
import MainContent from "components/MainContent";
import AppSideNav from "components/SideNav/AppSideNav";
import { GoogleAnalyticsWrapper } from "components/GoogleAnalyticsWrapper";
import { NotificationContainer } from "react-notifications";
import CarouselBanner from "components/Banners/views/Carousel/CarouselBanner";
import AdBanner from "components/Banners/views/AdBanner";
import { GameNav } from "@ugitgud/legos/components/nav/GameNav/GameNav";

// For login and signup pages we don't want to display sideNav or Masthead
function isAccountPage(location) {
  const accountPages = ["/signup", "/login", "/recover-account", "/reset-password", "/verification", "/about"];
  if (location && location.pathname) {
    return accountPages.includes(location.pathname);
  }
  return false;
}

function isPremiumPage(location) {
  if (location && location.pathname) {
    return location.pathname.match(/^\/boost\//);
  }
  return false;
}

const AppRouter = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [sideNavOpen, setSideNavOpen] = useState(false);

  useEffect(() => {
    if (location.pathname !== location.pathname.toLowerCase() && window) {
      history.replace(location.pathname.toLowerCase() + location.search);
    }
  }, [location.pathname]);

  let pageClassNames = classNames({
    dark: true,
    "desktop-container": true,
    "router-container": true,
    "no-masthead": isAccountPage(location),
    "no-padding":
      location.pathname.match(/(^\/$)/) ||
      location.pathname.match(/(^\/about$)/) ||
      location.pathname.match(/\/year-in-review/g) ||
      location.pathname.match(/\/yir-landing-page/g) ||
      location.pathname.match(/\/not-found-user-summoner-profile/g) ||
      location.pathname.match(/^\/boost/g) ||
      location.pathname.match(/^\/discover-boost/g) ||
      location.pathname.match(/^\/support-us/g) ||
      location.pathname.match(/^\/lg-splash/g) ||
      location.pathname.match(/^\/verify/g),
  });

  return (
    <div className={pageClassNames}>
      {/* Site Takeover & Pushdown Ads */}
      <GameNav selectedGame="lol" className="fixed top-0 left-0 z-[999]" />
      {!isAccountPage(location) && <Masthead sideNavOpen={sideNavOpen} setSideNavOpen={setSideNavOpen} />}
      <div id="page-content">
        {!isAccountPage(location) && !isPremiumPage(location) && <AppSideNav open={sideNavOpen} setOpen={setSideNavOpen} />}
        <div style={{ width: "100%" }}>
          <MediaQuery min="DESKTOP_SMALL" max="DESKTOP_LARGE" isClient renderNullOnFail>
            <AdBanner></AdBanner>
            <CarouselBanner></CarouselBanner>
          </MediaQuery>
          <MainContent />
        </div>
      </div>
      <Route path="/" component={GoogleAnalyticsWrapper} />
      <NotificationContainer />
    </div>
  );
};

export default AppRouter;
