import React, { useState, useEffect } from "react";
import { ReactComponent as GGIcon } from "svg/ggbet/GG-Icon.svg";
import { ReactComponent as GGBetLogo } from "svg/ggbet/GGBet-Logo.svg";
import { ReactComponent as LiveRedDot } from "svg/ggbet/live-red-dot.svg";
import { ReactComponent as LeagueDesktopIcon } from "svg/ggbet/League-Desktop-Icon.svg";
import { ReactComponent as LiveIndicationText } from "svg/ggbet/Live-Indication-Text.svg";

const AdBanner = (props) => {
  const [games, setGames] = useState();
  const ggBetSupportedLangs = [
    "cn",
    "en",
    "pt",
    "de",
    "pl",
    "ja",
    "uk-ua",
    "es",
    "fr-ca",
    "en-ca",
    "ro",
    "hu",
    "th",
    "hi",
    "vi",
    "tl-ph",
  ];

  const leagues = ["LCS", "LCK", "LEC", "LCO", "TCL", "PCS", "VCS", "LJL", "LVP", "PRM", "LHE"];
  let sanitizedIndexOfGames = [];

  const gamesFromBetGG = () => {
    fetch("https://u.gg/affiliates/ugg/json.json")
      .then((response) => {
        return response.json();
      })
      .then((responseJson) => {
        if (responseJson?.Sport?.eSports) {
          //console.log(responseJson);
          setGames(responseJson);
        }
      })
      .catch((error) => {
        console.log("GGBETS ERROR: " + error);
      });
  };

  const translatedBetNow = () => {
    let betNowText;
    switch (window.navigator.language) {
      // Realizar apuesta - Spanish
      case "es":
      case "es_es":
        betNowText = <div style={{ fontSize: "9px", width: "100%" }}>Realizar apuesta</div>;
        break;
      // JETZT WETTEN - German
      case "de":
      case "de-DE":
        betNowText = <div style={{ fontSize: "9px", width: "100%" }}>Wette platzieren</div>;
        break;
      // बेट लगाएं - Hindi
      case "hi":
        betNowText = <div style={{ width: "100%" }}>बेट लगाएं</div>;
        break;
      // Fogadás elhelyezése - Hungarian
      case "hu":
        betNowText = <div style={{ fontSize: "7px", width: "100%" }}>Fogadás elhelyezése</div>;
        break;
      // ベットする - Japanese
      case "ja":
        betNowText = <div style={{ width: "100%" }}>ベットする</div>;
        break;
      // Đặt cược - Vietnamese
      case "vi":
        betNowText = <div style={{ width: "100%" }}>Đặt cược</div>;
        break;
      // Fazer aposta - Portugese (Brazil)
      case "pt-BR":
        betNowText = <div style={{ width: "100%" }}>Fazer aposta</div>;
        break;
      // Zakład - Polish
      case "pl":
        betNowText = <div style={{ width: "100%" }}>Zakład</div>;
        break;
      // เดิมพัน - Thai
      case "th":
        betNowText = <div style={{ width: "100%" }}>เดิมพัน</div>;
        break;
      // Plasați Pariu - Romanian
      case "ro":
        betNowText = <div style={{ fontSize: "10px", width: "100%" }}>Plasați Pariu</div>;
        break;
      // Maglagay ng Pusta - Philipino
      case "fil":
        betNowText = <div style={{ fontSize: "7px", width: "100%" }}>Maglagay ng Pusta</div>;
        break;
      default:
        betNowText = <div style={{ width: "100%" }}>BET NOW</div>;
        break;
    }
    return betNowText;
  };

  const createDeepLink = (matchID) => {
    let link =
      "https://mercurybest.com/gg/gb/?lp=00&param=ugg_ww-eb-reg&deeplink=/en/esports/match/5:fd5e42c5-eaf3-4daa-b03d-6767b5992e1f";
    let browserlang = window.navigator.userLanguage || window.navigator.language;
    let userlang = browserlang[0] + browserlang[1];
    let convertedBrowserLang = "en";
    for (let i = 0; i < ggBetSupportedLangs.length; i++) {
      if (userlang === "uk") {
        convertedBrowserLang = "uk-ua";
      } else if (browserlang === "tl") {
        convertedBrowserLang = "tl-ph";
      } else if (browserlang === "fr") {
        convertedBrowserLang = "fr-ca";
      } else if (browserlang === ggBetSupportedLangs[i]) {
        convertedBrowserLang = ggBetSupportedLangs;
      } else {
        convertedBrowserLang = "en";
      }
    }
    let newLink = link.slice(0, 67) + convertedBrowserLang + link.slice(69, 84) + matchID;
    return newLink;
  };

  const createDeepLinkPromo = () => {
    // let browserlang = window.navigator.userLanguage || window.navigator.language;
    // let convertedBrowserLang = null;
    // for(let i = 0; i< ggBetSupportedLangs.length; i++){
    //   if(browserlang === ggBetSupportedLangs[i]){
    //     convertedBrowserLang = ggBetSupportedLangs;
    //   }else{
    //     convertedBrowserLang = "en"
    //   }
    // }
    let newLink = "https://mercurybest.com/gg/gb/?lp=00&param=ugg_ww-gift&go=sitereg&deeplink=/en/league-of-legends/";
    return newLink;
  };

  const sanitizeTime = (date) => {
    let currentTime = new Date();
    let convertedDate = new Date(date);
    let currentTimeConverted = currentTime.toLocaleTimeString([], { month: "numeric", day: "numeric", hour: "numeric" });
    let convertedDateConverted = convertedDate.toLocaleTimeString([], { month: "numeric", day: "numeric", hour: "numeric" });
    if (currentTimeConverted === convertedDateConverted) {
      return (
        <div className="time-tag-time">
          <LiveIndicationText className="live-indication-text"></LiveIndicationText>
        </div>
      );
    } else {
      let sanitizedDate = convertedDate.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" });
      return sanitizedDate;
    }
  };

  const sanitizeDate = (date) => {
    let currentTime = new Date();
    let convertedDate = new Date(date);
    let currentTimeConverted = currentTime.toLocaleTimeString([], { month: "numeric", day: "numeric", hour: "numeric" });
    let convertedDateConverted = convertedDate.toLocaleTimeString([], { month: "numeric", day: "numeric", hour: "numeric" });

    if (currentTimeConverted === convertedDateConverted) {
      return null;
    } else {
      let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let sanitizedDate = months[parseInt(date.slice(5, 7)) - 1] + " " + date.slice(8, 10);
      return sanitizedDate;
    }
  };

  // This function is present because the API will switch the position of the Teams and
  // the competitiors so the Logos will get swaped. Assumes that there is only two competitors.
  // Returns the rest of the link for the Logo.
  const getLogoFromTeam = (teamName, competitorsArray) => {
    let teamLogo = "";
    if (teamName === competitorsArray[0].Name) {
      teamLogo = competitorsArray[0].Logo;
    } else {
      //return the only other option which is location 1.
      teamLogo = competitorsArray[1].Logo;
    }
    return "https://" + teamLogo;
  };

  const sanitizeLeagues = (games) => {
    let tempEvent = JSON.parse(JSON.stringify(games.Sport.eSports.Events[0]));
    // tempEvent.Matches = [];

    // for (let event of games.Sport.eSports.Events) {
    //   for (let match of event.Matches) {
    //     if (tempEvent.Matches.length >= 4) {
    //       break;
    //     }
    //     tempEvent.Matches.push(match);
    //   }
    // }
    return tempEvent;
  };

  useEffect(() => {
    let timer = setInterval(() => {
      let toggle = window.sessionStorage.getItem("ggbet");
      if (toggle) {
        gamesFromBetGG();
        clearInterval(timer);
        window.document.getElementsByClassName("carousel-banner-container")[0].style.display = "none";
      }
    }, 1000);
  }, []);

  if (games === undefined) {
    return null;
  } else {
    sanitizedIndexOfGames = sanitizeLeagues(games);
    return (
      <div className="gg-bet-banner-worlds-promo">
        <a href={createDeepLinkPromo()}>
          <div className="gg-bet-logo">
            <GGBetLogo></GGBetLogo>
          </div>
        </a>
        <div>
          <div className="gg-bet-event">
            <div className="league-symbol-svg">
              <LeagueDesktopIcon></LeagueDesktopIcon>
            </div>
            <div className="gg-bet-event-name">
              {!sanitizedIndexOfGames?.Matches.length ? "New Games Coming Soon" : sanitizedIndexOfGames?.Name}
            </div>
            <div className="ggbet-logo-small-svg">
              <GGBetLogo></GGBetLogo>
            </div>
          </div>

          <div className="gg-bet-event-matches">
            {!sanitizedIndexOfGames?.Matches.length
              ? null
              : sanitizedIndexOfGames?.Matches.slice(0, 4).map((Matches, index) => {
                  let temp = index === 0 ? "gg-bet-event-match-highlight gg-bet-event-match" : "gg-bet-event-match";
                  return (
                    <a key={index} href={createDeepLink(Matches.ID)}>
                      <div className={temp}>
                        <div className="time-tag-container">
                          <div className="time-tag-time">{sanitizeTime(Matches.StartDate)}</div>
                          <div className="time-tag-date">{sanitizeDate(Matches.StartDate)}</div>
                        </div>

                        <div className="team-container">
                          {/* First team row    */}
                          <div className="match-body-team">
                            <div className="match-body-logo-name">
                              <div style={{ display: "flex", width: "18px", justifyContent: "center" }}>
                                <img
                                  className="match-body-logo"
                                  src={getLogoFromTeam(Matches.Bets[0].Odds[0].Name, Matches.Competitors)}
                                />
                              </div>
                              <div className="match-body-name">{Matches.Bets[0].Odds[0].Name}</div>
                            </div>
                            <div className="match-body-data-odds">{Matches.Bets[0].Odds[0].Value}</div>
                          </div>
                          {/* Second team row, Needs extra padding to seperate the teams. */}
                          <div className="match-body-team" style={{ paddingTop: "3px" }}>
                            <div className="match-body-logo-name">
                              <div style={{ display: "flex", width: "18px", justifyContent: "center" }}>
                                <img
                                  className="match-body-logo"
                                  src={getLogoFromTeam(Matches.Bets[0].Odds[1].Name, Matches.Competitors)}
                                />
                              </div>
                              <div className="match-body-name">{Matches.Bets[0].Odds[1].Name}</div>
                            </div>
                            <div className="match-body-data-odds">{Matches.Bets[0].Odds[1].Value}</div>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })}
          </div>
        </div>
        <div className="gg-bet-promo-message">
          <div className="gg-bet-promo-message-text">
            Receive a <b style={{ color: "#FFFFFF" }}>FREE $5 bet</b> with <b style={{ color: "#FFFFFF" }}>PROMO CODE:</b>
          </div>
          <div className="gg-bet-promo-message-code">
            <div style={{ width: "100%" }}>UGG</div>
          </div>
          <a href={createDeepLinkPromo()}>
            <div className="gg-bet-promo-message-button">{translatedBetNow()}</div>
          </a>
        </div>
        <div className="ad-indication">Ad</div>
      </div>
    );
  }
};

export default AdBanner;
