import { window } from "global";
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { useGlobal } from "reactn";
import { useUserPremiumState } from "lib/hooks/userHooks";

import { AppBannerView } from "components/Banners/views/AppBanner";

function isHidden(pathname) {
  return matchPath(pathname, {
    path: [
      "/support-us",
      "/about",
      "/faq",
      "/privacy-policy",
      "/privacy-shield",
      "/careers",
      "/terms-of-service",
      "/login",
      "/signup",
    ],
    exact: true,
    strict: false,
  });
}

const CarouselBanner = (props) => {
  const [loggingIn] = useGlobal("loggingIn");
  const { isPremium } = useUserPremiumState();
  const location = useLocation();
  const hidden = isHidden(location.pathname);
  // const [slideIndex, setSlideIndex] = useState(Math.floor(Math.random() * 4) * 2);
  const [slideIndex, setSlideIndex] = useState(Math.floor(Math.random() * 4));
  const interval = useRef();

  const slides = useMemo(() => {
    return [
      <AppBannerView textOption={0} />,
      <AppBannerView textOption={1} />,
      <AppBannerView textOption={2} />,
      <AppBannerView textOption={3} />,
    ];
  }, []);

  useEffect(() => {
    interval.current = setInterval(() => {
      setSlideIndex((prevSlide) => (prevSlide + 1) % slides.length);
    }, 10000);

    return () => interval.current && clearInterval(interval.current);
  }, []);

  if (!window || hidden || loggingIn || isPremium) {
    return null;
  }

  return (
    <div className="carousel-banner-container">
      {/* {slides.map((slide, index) => {
        return (
          <div key={index} className={classnames("slide-container", {
            "slide-container_active": slideIndex === index
          })}>
            {slide}
          </div>
        )
      })} */}
    </div>
  );
};

export default CarouselBanner;
