import React from "react";
import { Route } from "react-router-dom";

const RouteStatus = (props) => {
  const { statusCode, path } = props;
  return (
    <Route
      path={path}
      render={({ staticContext }) => {
        // we have to check if staticContext exists
        // because it will be undefined if rendered through a BrowserRouter
        if (staticContext) {
          staticContext.statusCode = statusCode;
        }

        return props.children;
      }}
    />
  );
};

export default RouteStatus;
