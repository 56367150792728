// https://support.playwire.com/v1/docs/ad-units-array-for-ads-api#tagless-ad-units
export enum TaglessAdUnits {
  BottomRail = "bottom_rail",
  FlexLeaderboard = "flex_leaderboard",
  LeftRail = "left_rail",
  RightRail = "right_rail",
  CornerAdVideo = "corner_ad_video",
  StickySidebar = "sticky_sidebar",
  Sidebar = "sidebar",
  InContent = "in_content",
}

// https://support.playwire.com/v1/docs/ad-units-array-for-ads-api#2
// ATF = Above the Fold
// BTF = Below the Fold
export enum TagBasedAdUnits {
  LeaderboardATF = "leaderboard_atf",
  LeaderboardBTF = "leaderboard_btf",
  MediumRectATF = "med_rect_atf",
  MediumRectBTF = "med_rect_btf",
  SkyscraperATF = "sky_atf",
  SkyscraperBTF = "sky_btf",
}

export type RampWindow = {
  ramp: {
    que: any[];
    passiveMode: boolean;
    forcePath: string;
    settings: {
      slots: object;
    };
    setPath: (path: string) => void;
    addUnits: (units: any) => Promise<void>;
    displayUnits: Function;
    destroyUnits: (slots: string[] | "all") => Promise<void>;
    flexLbShown: boolean;
  };
} & Window &
  typeof globalThis;
