import { useEffect, useMemo } from "react";
import { useGlobal } from "reactn";
import { STATIC_LOL_URL } from "@outplayed/riot-assets";
import { useJSONFetcher } from "@outplayed/json-fetcher";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

/***********************************
 * Search Bar Pages
 ***********************************/
const SECTIONS = [
  { title: "Build", suffix: "", path: "build" },
  { title: "Pro Builds", suffix: " Pro Builds", path: "pro-build" },
  { title: "Counters", suffix: " Counters", path: "counters" },
];
const ITEM_SECTIONS = [{ title: "Items", suffix: "", path: "" }];

function getSearchBarPage(key, displayName, champId, lowerId, pageName, language) {
  const isNickname = key.toLowerCase() !== lowerId && key.toLowerCase() !== displayName.toLowerCase();

  if (pageName === "pro-build") {
    return {
      key: key,
      displayName: displayName,
      url: `https://probuildstats.com/champion/${lowerId}`,
      champId: champId,
      section: pageName,
      isNickname,
      targetHref: true,
    };
  }

  if (language !== "en_US" && pageName == "build") {
    return {
      key: key,
      displayName: displayName,
      url: `/lol/${language.toLowerCase()}/champions/${lowerId}/${pageName}`,
      champId: champId,
      section: pageName,
      isNickname,
    };
  }

  if (pageName === "build" || pageName === "aram") {
    const kaynPath = champId != 141 ? `/lol/champions/${lowerId}/${pageName}` : `/lol/champions/red/${lowerId}/${pageName}`;
    return {
      key: key,
      displayName: displayName,
      url: kaynPath,
      champId: champId,
      section: pageName,
      isNickname,
    };
  }

  return {
    key: key,
    displayName: displayName,
    url: `/lol/champions/${lowerId}/${pageName}`,
    champId: champId,
    section: pageName,
    isNickname,
  };
}

function useSearchBarPages({ language, ssr, skip } = {}) {
  const [globalLanguage] = useGlobal("language");
  const [savedResults, setSavedResults] = useGlobal("search-bar-pages");
  const { useChampionMini, getNormalizedChampionName, getItems, getNormalizedItemNameFromId, useItemsWiki } =
    getRiotAssetsContext();
  const championMini = useChampionMini({ language, ssr, skip }); // includes backup champions
  const itemsJSON = getItems();
  const itemsList = useItemsWiki({ ssr });
  const availableItems = itemsList?.data?.availableItems;

  const envDir = process.env.RIOT_PATCH_ASSETS === "staging" ? "staging" : "prod";
  const url = `${STATIC_LOL_URL}/riot_patch_update/${envDir}/champion-nicknames.json`;
  const championNicknames = useJSONFetcher(url, { ssr, skip });

  const searchBarPages = useMemo(() => {
    if (savedResults) {
      return savedResults;
    } else if (!championMini.data || !championNicknames.data) {
      return null;
    }
    // Build search bar pages
    const champPages = SECTIONS.map((curSection) => {
      const pages = Object.values(championMini.data).reduce((accChampion, curChampion) => {
        const { name, key, id } = curChampion;
        const normalizedName = getNormalizedChampionName(key);
        const nicknames = championNicknames.data[id] || [];

        const variations = new Set([id, name, ...(nicknames || [])]);
        const championPages = [...variations].map((variation) =>
          getSearchBarPage(
            `${variation}${curSection.suffix}`,
            `${name}${curSection.suffix}`,
            key,
            normalizedName,
            curSection.path,
            globalLanguage,
          ),
        );
        return accChampion.concat(championPages);
      }, []);
      return { title: curSection.title, pages };
    });
    const itemPages =
      itemsJSON &&
      ITEM_SECTIONS.map((curSection) => {
        const pages = Object.keys(itemsJSON)
          .filter((id) => availableItems?.includes(parseInt(id)))
          .map((itemId) => {
            return {
              key: itemsJSON[itemId].name,
              displayName: itemsJSON[itemId].name,
              url: `/lol/items/${getNormalizedItemNameFromId(itemId, { optionalData: itemsJSON })}`,
              itemId: itemId,
              section: "items",
            };
          });
        return { title: curSection.title, pages };
      });
    const searchBarPages = [...champPages, ...(itemPages || [])];
    return searchBarPages;
  }, [savedResults, championMini.data, championNicknames.data, itemsJSON, globalLanguage]);

  // Save results globally so we don't have to build the pages again
  // Commented out because the lang will go back to the previous lang of a saved page.
  // useEffect(() => {
  //   if (!savedResults && searchBarPages) {
  //     setSavedResults(searchBarPages);
  //   }
  // }, [savedResults, searchBarPages, globalLanguage])

  if (championMini.loading || championNicknames.loading) {
    return { data: null, error: null, loading: !championMini.loading || !championNicknames.loading };
  } else if (championMini.error || championNicknames.error) {
    return { data: null, loading: false, error: !championMini.error || !championNicknames.error };
  } else {
    return { data: searchBarPages, loading: false, error: null };
  }
}

/****************************************************
 * Search Pages (OpenSearch, Search Bar Matching)
 ****************************************************/
function getSearchPages(name, lowerId, displayName, champId, language) {
  const isNickname = name.toLowerCase() !== lowerId && name.toLowerCase() !== displayName.toLowerCase();

  return [
    {
      key: `${name}`,
      displayName: `${displayName}`,
      id: champId,
      url: language !== "en_US" ? `/lol/${language.toLowerCase()}/champions/${lowerId}/build` : `/lol/champions/${lowerId}/build`,
      isNickname,
      isDefaultPage: true,
    },
    {
      key: `${name} Items`,
      displayName: `${displayName} Items`,
      id: champId,
      url: `/lol/champions/${lowerId}/items`,
      isNickname,
    },
    {
      key: `${name} Spells`,
      displayName: `${displayName} Spells`,
      id: champId,
      url: `/lol/champions/${lowerId}/spells-abilities`,
      isNickname,
    },
    {
      key: `${name} Abilities`,
      displayName: `${displayName} Abilities`,
      id: champId,
      url: `/lol/champions/${lowerId}/spells-abilities`,
      isNickname,
    },
    {
      key: `${name} Runes`,
      displayName: `${displayName} Runes`,
      id: champId,
      url: `/lol/champions/${lowerId}/runes`,
      isNickname,
    },
    {
      key: `${name} Rune Sets`,
      displayName: `${displayName} Rune Sets`,
      id: champId,
      url: `/lol/champions/${lowerId}/runes-sets`,
      isNickname,
    },
    {
      key: `${name} Counters`,
      displayName: `${displayName} Counters`,
      id: champId,
      url: `/lol/champions/${lowerId}/counters`,
      isNickname,
    },
    {
      key: `${name} Matchups`,
      displayName: `${displayName} Matchups`,
      id: champId,
      url: `/lol/champions/${lowerId}/matchups`,
      isNickname,
    },
    {
      key: `${name} Duos`,
      displayName: `${displayName} Duos`,
      id: champId,
      url: `/lol/champions/${lowerId}/duos`,
      isNickname,
    },
    {
      key: `${name} Pro Builds`,
      displayName: `${displayName} Pro Builds`,
      id: champId,
      url: `https://probuildstats.com/champion/${lowerId}`,
      isNickname,
      targetHref: true,
    },
  ];
}

function useSearchPages({ language, ssr, skip } = {}) {
  const [globalLanguage] = useGlobal("language");
  const [savedResults, setSavedResults] = useGlobal("search-pages");
  const { useChampionMini, getNormalizedChampionName, getItems, getNormalizedItemNameFromId } = getRiotAssetsContext();
  const championMini = useChampionMini({ language, ssr, skip }); // includes backup champions
  const itemsJSON = getItems();
  const availableItems = itemsJSON && Object.keys(itemsJSON);

  const envDir = process.env.RIOT_PATCH_ASSETS === "staging" ? "staging" : "prod";
  const url = `${STATIC_LOL_URL}/riot_patch_update/${envDir}/champion-nicknames.json`;
  const championNicknames = useJSONFetcher(url, { ssr, skip });

  const searchPages = useMemo(() => {
    if (savedResults) {
      return savedResults;
    } else if (!championMini.data || !championNicknames.data) {
      return null;
    }
    // Build search bar pages
    const champPages = Object.values(championMini.data).reduce((accChampion, curChampion) => {
      const { name, key, id } = curChampion;
      const normalizedName = getNormalizedChampionName(key);
      const nicknames = championNicknames.data[id] || [];

      const variations = new Set([id, name, ...(nicknames || [])]);
      const championPages = [...variations].reduce((acc, variation) => {
        const pages = getSearchPages(variation, normalizedName, name, key, globalLanguage);
        return acc.concat(pages);
      }, []);
      return accChampion.concat(championPages);
    }, []);

    const itemPages =
      itemsJSON &&
      Object.keys(itemsJSON)
        .filter((id) => availableItems?.includes(parseInt(id)))
        .map((itemId) => {
          return {
            key: itemsJSON[itemId].name,
            displayName: itemsJSON[itemId].name,
            url: `/lol/items/${getNormalizedItemNameFromId(itemId, { optionalData: itemsJSON })}`,
            itemId: itemId,
            section: "items",
          };
        });
    const searchBarPages = [...champPages, ...(itemPages || [])];
    return searchBarPages;
  }, [savedResults, championMini.data, championNicknames.data, itemsJSON, globalLanguage]);

  // Save results globally so we don't have to build the pages again
  // Commented out because the lang will go back to the previous lang of a saved page.
  // useEffect(() => {
  //   if (!savedResults && searchPages) {
  //     setSavedResults(searchPages);
  //   }
  // }, [savedResults, searchPages, globalLanguage])

  if (championMini.loading || championNicknames.loading) {
    return { data: null, error: null, loading: !championMini.loading || !championNicknames.loading };
  } else if (championMini.error || championNicknames.error) {
    return { data: null, loading: false, error: !championMini.error || !championNicknames.error };
  } else {
    return { data: searchPages, loading: false, error: null };
  }
}

export { useSearchBarPages, useSearchPages };
