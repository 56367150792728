import classNames from "classnames";
import React, { useState, useEffect, useRef } from "react";
import { useGlobal } from "reactn";

const AVAILABLE_REGIONS = [
  { value: "na1", label: "North America", shorthandLabel: "NA" },
  { value: "euw1", label: "Europe West", shorthandLabel: "EUW" },
  { value: "eun1", label: "Europe Nordic & East", shorthandLabel: "EUN" },
  { value: "kr", label: "Korea", shorthandLabel: "KR" },
  { value: "br1", label: "Brazil", shorthandLabel: "BR" },
  { value: "jp1", label: "Japan", shorthandLabel: "JP" },
  { value: "ru", label: "Russia", shorthandLabel: "RU" },
  { value: "oc1", label: "Oceania", shorthandLabel: "OCE" },
  { value: "tr1", label: "Turkey", shorthandLabel: "TR" },
  { value: "la1", label: "Latin America North", shorthandLabel: "LAN" },
  { value: "la2", label: "Latin America South", shorthandLabel: "LAS" },
  { value: "ph2", label: "Philippines", shorthandLabel: "PH" },
  { value: "sg2", label: "Singapore", shorthandLabel: "SG" },
  { value: "th2", label: "Thailand", shorthandLabel: "TH" },
  { value: "tw2", label: "Taiwan", shorthandLabel: "TW" },
  { value: "vn2", label: "Vietnam", shorthandLabel: "VN" },
];

const KEY_TO_AVAILABLE_REGION = AVAILABLE_REGIONS.reduce((acc, curr) => {
  acc[curr.value] = curr;

  return acc;
}, {});

const RegionSelectorV2 = () => {
  const [clientRegion, setClientRegion] = useGlobal("clientRegion");
  const [regionToApply, setRegionToApply] = useState(clientRegion);
  const [open, setOpen] = useState(false);
  const regionSelectorV2Ref = useRef();

  const clientRegionData = KEY_TO_AVAILABLE_REGION[clientRegion];

  useEffect(() => {
    if (window && open) {
      const outsideClick = (e) => {
        if (
          regionSelectorV2Ref.current &&
          e.target !== null &&
          !regionSelectorV2Ref.current.contains(e.target) &&
          document.contains(e.target)
        ) {
          setOpen(false);
        }
      };
      window.addEventListener("mousedown", outsideClick);

      return () => window.removeEventListener("mousedown", outsideClick);
    }
  }, [open]);

  return (
    <div className="region-selector-v2" ref={regionSelectorV2Ref}>
      <div
        className={classNames("region-tag", "selected", `bg-${clientRegionData.value}`)}
        onMouseDown={() => setOpen((prevState) => !prevState)}
      >
        {clientRegionData.shorthandLabel}
      </div>
      {open && (
        <div className="menu">
          <div className="title">Region</div>
          <div className="options">
            {AVAILABLE_REGIONS.map(({ value, shorthandLabel }) => (
              <div
                className={classNames("option", "region-tag", value === clientRegion ? `selected bg-${value}` : "")}
                onClick={() => {
                  localStorage.setItem("summonerRegion", value);
                  setClientRegion(value);
                }}
              >
                {shorthandLabel}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RegionSelectorV2;
