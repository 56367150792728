import React, { Component } from "react";
import PropTypes from "prop-types";

export default class CustomCheckbox extends Component {
  render() {
    const { className, checkboxId, isChecked, onChange, label } = this.props;

    return (
      <div className={`custom-checkbox ${className}`}>
        <div className="custom-checkbox_checkbox">
          <input id={checkboxId} type="checkbox" checked={isChecked} onChange={onChange} />
          <label htmlFor={checkboxId}></label>
        </div>
        {label && (
          <label htmlFor={checkboxId} className="custom-checkbox_label">
            {label}
          </label>
        )}
      </div>
    );
  }
}

CustomCheckbox.propTypes = {
  checkboxId: PropTypes.string.isRequired,
  // isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

CustomCheckbox.defaultProps = {
  className: "",
};
