// RESPONSIVE
export const DEFAULT_BREAKPOINT = "DESKTOP_LARGE";
export const WINDOW_BREAKPOINTS_RANGE = {
  MOBILE_SMALL: [0, 399],
  MOBILE_MEDIUM: [400, 599],
  MOBILE_LARGE: [600, 899],
  TABLET: [900, 1139],
  DESKTOP_SMALL: [1140, 1359],
  DESKTOP_MEDIUM: [1360, 1469],
  DESKTOP_LARGE: [1470, 9999999999],
};

export const WINDOW_BREAKPOINTS = {
  MOBILE: ["MOBILE_SMALL", "MOBILE_MEDIUM", "MOBILE_LARGE"],
  TABLET: "TABLET",
  DESKTOP: ["DESKTOP_SMALL", "DESKTOP_MEDIUM", "DESKTOP_LARGE"],
};
