import { window } from "global";
import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import { useUserPremiumState } from "lib/hooks/userHooks";
import { useLocation, matchPath, Link } from "react-router-dom";
import heartsIconURL from "svg/double-hearts-outline.svg";
import { ReactComponent as IconX } from "svg/x.svg";
import { ReactComponent as WindowsIcon } from "svg/windows-icon.svg";
import champSelect from "images/overwolf_app/champ-select.png";
import headToHead from "images/overwolf_app/head-to-head.png";
import inGame from "images/overwolf_app/in-game.png";
import runes from "images/overwolf_app/runes.png";
import build from "images/overwolf_app/build.png";
import { getStaticOverwolfImg } from "@outplayed/riot-assets";

export const AppBannerView = (props) => {
  const { textOption } = props;

  const textPairOptions = {
    0: {
      header: "THE APP IS HERE",
      sub_header: "The best Champ Select scouting.",
      teaser_image: <img src={getStaticOverwolfImg("champ-select", { webp: true })} alt="champ-select" />,
      utm: "utm_source=uggchampselectb",
    },
    1: {
      header: "THE APP IS HERE",
      sub_header: "Head-to-Head Live Game.",
      teaser_image: <img src={getStaticOverwolfImg("head-to-head", { webp: true })} alt="head-to-head" />,
      utm: "utm_source=uggh2hb",
    },
    2: {
      header: "THE APP IS HERE",
      sub_header: "In-game performance tracker.",
      teaser_image: <img src={getStaticOverwolfImg("in-game", { webp: true })} alt="in-game" />,
      utm: "utm_source=uggtrackerb",
    },
    3: {
      header: "THE APP IS HERE",
      sub_header: "Auto-import builds and runes.",
      teaser_image: (
        <>
          <img src={getStaticOverwolfImg("runes", { webp: true })} alt="runes" className="double-img" />
          <img src={getStaticOverwolfImg("build", { webp: true })} alt="build" className="double-img" />
        </>
      ),
      utm: "utm_source=uggimportb",
    },
  };

  // const backgroundStyle = {
  //   backgroundImage: `
  //   linear-gradient(to right, rgba(237, 237, 239, 0) 0%, #ededef 100%),
  //   url(${require("images/worlds_banner/background-img.png").default})
  //   `,
  //   backgroundSize: "auto 100%",
  //   backgroundRepeat: "no-repeat",
  //   backgroundColor: "#ededef"
  // }

  return (
    <div className="app-banner">
      {/* <img class="pbs-banner" src={require("images/worlds_banner/pbs-banner.png").default} alt="pbs banner" />
      <img class="worlds-banner" src={require("images/worlds_banner/worlds-banner.png").default} alt="worlds banner" /> */}
      <div className="app-preview">{textPairOptions[textOption].teaser_image}</div>
      <div className="text-box">
        <div className="header">{textPairOptions[textOption].header}</div>
        <div className="sub-header">{textPairOptions[textOption].sub_header}</div>
      </div>
      <div className="button-container">
        <Link className="btn-blue btn-blue_hover masthead_app-btn" to={"/app" + "?" + (textPairOptions[textOption].utm || "")}>
          <WindowsIcon className="win-icon" /> Download App
        </Link>
      </div>
      {/* <a className="btn-blue btn-blue_hover masthead_pbs-btn" href="https://probuildstats.com/">
        <img class="pbs-button" src={require("images/worlds_banner/pbs-button.png").default} alt="pbs button" /> Visit Now
      </a> */}
      {/*<span>Support our team and go ad-free for just $1/month.</span>&nbsp;
      <Link to="/support-us">AD-FREE</Link>
      <IconX className="x-icon" onClick={onHide} />*/}
    </div>
  );
};

const AppBanner = (props) => {
  const [loggingIn] = useGlobal("loggingIn");
  const { isPremium } = useUserPremiumState();
  const [show, setShow] = useState(false);
  const [textOption, setTextOption] = useState(Math.floor(Math.random() * 4));

  const location = useLocation();
  const match = matchPath(location.pathname, {
    path: ["/support-us", "/about", "/", "/app"],
    exact: true,
    strict: false,
  });

  useEffect(() => {
    setShow((window && sessionStorage.getItem("ad-free-banner")) !== "hide");
  }, []);

  useEffect(() => {
    setTextOption(Math.floor(Math.random() * 4));
  }, [location.pathname]);

  const onHide = () => {
    if (window) {
      sessionStorage.setItem("ad-free-banner", "hide");
    }
    setShow(false);
  };

  if (!window || match || !show || loggingIn || isPremium) {
    return null;
  }

  return <AppBannerView textOption={textOption} />;
};

export default AppBanner;
